import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entity } from '../models/entities/Entity';
import { Constants } from '../config/Constants';
import { isEmptyObject } from '@core/utils/object-utils/object-utils';

@Injectable({
  providedIn: 'root',
})
export class MercureService<T extends Entity> {
  #subject = new BehaviorSubject<T>({} as T);
  destroyEventEmitter = new EventEmitter<boolean>();

  subscribeToItemsIfPossible(items: T[], eventSource?: EventSource): EventSource | undefined {
    if (eventSource) {
      eventSource.close();
    }
    const subscribeUrl = new URL(`${Constants.API_ENDPOINT}/.well-known/mercure`);
    if (subscribeUrl && items.length) {
      items.forEach((item: T) => {
        subscribeUrl.searchParams.append('topic', `${item['@id']}`);
      });
      return this.subscribeToItems(subscribeUrl, items);
    }
    return undefined;
  }

  subscribeToSpecificTopic(items: T[], eventSource: EventSource | null, topic?: string): EventSource | null {
    const url = new URL(`${Constants.API_ENDPOINT}/.well-known/mercure`);
    if (eventSource) {
      eventSource.close();
    }
    if (topic) {
      url.searchParams.append('topic', topic);
      return this.subscribeToItems(url, items, true);
    }
    return null;
  }

  private subscribeToItems(subscribeUrl: URL, items: T[], add = false) {
    const eventSource = this.createEventSource(subscribeUrl);
    this.getUpdates(eventSource).subscribe((data) => {
      const foundIndex = items.findIndex((el) => el['@id'] === data['@id']);
      if (foundIndex === -1 && !isEmptyObject(data) && add) {
        items.unshift(data);
      } else {
        items[foundIndex] = data;
      }
    });
    return eventSource;
  }

  getUpdates(eventSource?: EventSource | null): Observable<T> {
    if (eventSource) {
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (!isEmptyObject(data)) this.#subject.next(data);
      };

      eventSource.onerror = (error) => {
        this.#subject.error(error);
        eventSource.close();
      };
    }
    return this.#subject.asObservable();
  }

  createEventSource(subscribeUrl: URL): EventSource {
    return new EventSource(subscribeUrl);
  }

  unsubscribeFromItems(eventSource?: EventSource) {
    if (eventSource) {
      eventSource.close();
    }
    this.#subject.next({} as T); // Reset subject to empty entity
  }
}
